/* StatPathViz.css */ 

@font-face {
  font-family: 'Organetto-cndlight-edit';
  src: url('../../fonts/organetto-cndlight-edit.otf') format('opentype');
}

.help-icon {
  font-family: 'Organetto-cndlight-edit', sans-serif;
  cursor: pointer;
  fill: hsl(207, 44%, 30%); 
}

.help-icon:hover {
  filter: brightness(1.35); /* Increase brightness */
}

svg {
  overflow: visible;
}

.stat-title {
  font-family: 'Organetto-cndlight-edit', sans-serif;
}

.stat-window {
  width: 95%;
  margin-left: 10px;
  margin-bottom: 80px;
}

.team-logo {
  transition: filter 0.1s ease; /* Smooth transition for the filter effect */
}

.team-logo:hover {
  filter: brightness(1.35); /* Increase brightness */
  cursor: pointer;
}

.tooltip {
  position: absolute;
  background-color: white;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  pointer-events: none;  /* Prevents the tooltip from interfering with mouse events */
  opacity: 0; /* Start off hidden */
  transition: opacity 0.2s;
}
