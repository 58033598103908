/* HelpSidebar.css */

@font-face {
  font-family: 'Organetto-bold-edit';
  src: url('../fonts/organetto-bold-edit.otf') format('opentype');
}

@font-face {
  font-family: 'Organetto-cndlight-edit';
  src: url('../fonts/organetto-cndlight-edit.otf') format('opentype');
}

.about {
  font-family: 'Organetto-bold-edit', sans-serif;
}

.help-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  right: -100%; /* Start off-screen */
  width: 50%; 
  height: 100%;
  background-color:rgb(255, 255, 246);
  box-shadow: -2px 0 5px rgba(0,0,0,0.5);
  transition: right 0.2s ease-in-out;
  z-index: 1000; /* Ensure it's above other content */
}
  
.help-sidebar.open {
  right: 0; /* Slide in */
}

.close-button {
  font-family: 'Organetto-bold-edit', sans-serif;
  position: absolute;
  top: 10px;
  right: 10px;
}

.help-content {
  padding: 20px;
  padding-top: 5px;
  background-color: white;
  border: 2px solid black;
  border-radius: 6px;
  overflow-y: auto;
  max-height: 85%;
  width: 90%; 
}
  