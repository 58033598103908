/* Dashboard.css */ 

@font-face {
  font-family: 'Organetto-bold-edit';
  src: url('../fonts/organetto-bold-edit.otf') format('opentype');
}

@font-face {
  font-family: 'Organetto-cndlight-edit';
  src: url('../fonts/organetto-cndlight-edit.otf') format('opentype');
}

.button {
  position: relative;
  font-family: 'Organetto-cndlight-edit', sans-serif;
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.35em;
  margin: 20px 0;
  transition: text-shadow 0.1s ease-in-out;
  z-index: 1;
}

.button:hover {
  text-shadow: 1px 1px 2px #ece4df;
}

.container {
  display: flex;
  height: 100vh;
}

.content {
  width: 80%;
  background-color: rgb(255, 255, 246);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px; 
  overflow-y: auto;
}

.header {
  position: absolute;
  font-family: 'Organetto-bold-edit', sans-serif;
  top: 0;
  left: 0;
  width: 20%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  z-index: 1;
}

.input-form {
  display: flex;
  width: 95%; 
  margin-top: 5px;
}

.input-form input {
  flex-grow: 1;
  width: 100%; 
  height: 30px;
  font-size: 1.2em;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  box-shadow: 1px 2.8px 8px rgba(0, 0, 0, 0.3); 
  outline: none;
  margin-right: 10px;
}

.input-form input:focus {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.sidebar {
  position: relative;
  width: 20%;
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #705a4d 0%, #705a4d 99%, #ddbaa636 100%);
}

.sidebar-buttons {
  position: absolute; /* Position buttons relative to the sidebar */
  width: 100%; /* Make the buttons div take up the full width of the sidebar */
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1; /* Ensure buttons are positioned above the images */
}

.sidebar-img {
  position: absolute; /* Position the images absolute to the .sidebar */
  width: 100%;
  height: 40%;
  object-fit: contain;
  filter: grayscale(100%) opacity(0.1);
  transform: scale(0.5); /* Scale the image size */
}

.sidebar-img:nth-child(1) {
  top: -7%;
}

.sidebar-img:nth-child(2) {
  top: 16%;
}

.sidebar-img:nth-child(3) {
  top: 42%;
}

.sidebar-img:nth-child(4) {
  top: 69%;
}

.send-button {
  padding: 5px 10px;
  background-color: white;
  border: 1.5px solid rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  box-shadow: 1px 2.8px 8px rgba(0, 0, 0, 0.3); 
  color: #705a4d; 
  font-size: 20px;
}

.send-button:active {
  transform: scale(0.95);
}

.send-button:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.svg-container {
  width: 90%;
  height: 80%;
  margin: auto;
}