/* DataTotalsPanel.css */ 

@font-face {
  font-family: 'Organetto-cndlight-edit';
  src: url('../../fonts/organetto-cndlight-edit.otf') format('opentype');
}

.nhl-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 0;
  height: 100%;
  width: 95%;
}
  
.panel {
  width: 90%;
  height: 20vh;
  margin: 10px 0; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url('https://i.imgur.com/dNOQjdd.png'); 
  background-size: cover;
  background-repeat: no-repeat; 
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.panel:hover {
  transform: scale(1.02); 
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2); 
  background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url('https://i.imgur.com/dNOQjdd.png'); 
}

.panel-description {
  font-family: 'Organetto-cndlight-edit', sans-serif;
}