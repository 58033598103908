/* SeasonDashboard.css */ 

@font-face {
  font-family: 'Organetto-bold-edit';
  src: url('../fonts/organetto-bold-edit.otf') format('opentype');
}

.footer {
  width: 95%;
  opacity: 0.5;
  margin: 10px;
  text-align: center;
}

.season-dashboard {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #eeede6;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  border-radius: 8px;
  overflow-y: scroll;
  padding: 0;
}

.season-select {
  margin: 10px;
  margin-top: 4px;
  padding: 2px;
}

.title {
  font-family: 'Organetto-bold-edit', sans-serif;
  margin-top: 0.5em;
  margin-bottom: 0.2em;
}