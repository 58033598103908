/* PlayerDashboard.css */ 

@font-face {
    font-family: 'Organetto-bold-edit';
    src: url('../fonts/organetto-bold-edit.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'Organetto-cndlight-edit';
    src: url('../fonts/organetto-cndlight-edit.otf') format('opentype');
  }
  
  .player-dashboard {
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #eeede6;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    border-radius: 8px;
    overflow-y: scroll;
    padding: 0;
  }
  
  .title {
    font-family: 'Organetto-bold-edit', sans-serif;
    margin-top: 0.5em;
    margin-bottom: 0.2em;
  }
  
  .uc-text {
    font-family: 'Organetto-cndlight-edit', sans-serif;
    margin: auto;
  }