/* SportsGrid.css */

@font-face {
  font-family: 'Organetto-cndlight-edit';
  src: url('../fonts/organetto-cndlight-edit.otf') format('opentype');
}

body, html {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  z-index: 0;
  pointer-events: none;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.column {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
}

.center-content {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column:hover {
  transform: scale(1.05);
  z-index: 2;
  filter: brightness(1.2);
}

.logo {
  max-width: 90%;
  max-height: 87vh;
}

.mlb-logo {
  max-width: 80%;
}

.nba-logo {
  max-width: 70%;
  transform: translateY(5%);
}

.nfl-logo {
  max-width: 65%;
  transform: translateY(5%);
}

.nhl-logo {
  max-width: 80%;
  transform: translateY(5%);
}

.hovered::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.lock-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  text-align: center;
  pointer-events: none;
}

.lock-icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  color: grey;
  opacity: 0.5;
}

.coming-soon-text {
  display: block;
  margin-top: 10px;
  color: rgb(48, 48, 48);
  font-weight: bold;
  font-size: 18px;
  font-family: 'Organetto-cndlight-edit', sans-serif;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.7);
  opacity: 0;
}

.column:hover .lock-icon {
  opacity: 0.9;
}

.column:hover .coming-soon-text {
  opacity: 0.9;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .column:hover {
    transform: scale(1.02);
  }
  .row {
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
  }

  .column {
    flex: 1;
    width: 100%;
    min-height: 25vh;
  }

  .logo {
    max-height: 20vh;
  }

  .mlb-logo {
    max-width: 60%;
  }

  .nba-logo {
    max-width: 80%;
  }

  .nfl-logo {
    max-width: 80%;
  }

  .nhl-logo {
    max-width: 75%;
  }

  .lock-icon {
    width: 30px;
    height: 30px;
    opacity: 0.9;
  }

  .coming-soon-text {
    font-size: 14px;
    opacity: 0.9;
  }

  body, html {
    overflow-y: auto;
  }
}